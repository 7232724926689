<template>
  <v-container fluid>
    <v-row class="px-3">
      <v-col cols="12" ref="top">
        <h1 class="secondary--text">Detalhes do Beneficiário</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <PrincipalInformations
          class="mt-5"
          :isDetail="true"
          :holderInformationData="holderInformationData"
          :showHeaderPage="true"
          :showAdmissionDate="true"
          :showCardNumber="showCardNumber"
          :showRegistrationPlate="showRegistrationPlate"
        />

        <PlanInformation
          class="mt-5"
          :contractedPlanData="contractedPlanData"
          :showHeaderPage="true"
          :showEligibility="true"
          :showStartValidityDate="true"
          :showEndValidityDate="true"
          :showFamilyCode="true"
          :showFamilyCodeSequential="true"
          :showCancellationReason="true"
          :isDetail="true"
        />

        <template v-if="insuredType && insuredType === 'holder'">
          <ComplementaryInformations
            class="pt-0"
            :holderComplementaryData="holderComplementaryData"
            :propsGenders="genders"
            :propsMaritalStatus="maritalStatus"
            :showHeaderPage="true"
            :isFreeMovement="false"
            :isDetail="true"
            :showRegistrationPlate="false"
          />
          <AddressInformations
            class="pt-0"
            :addressData="addressData"
            :showHeaderPage="true"
            :isFreeMovement="false"
            :isDetail="true"
          />
          <BankInformations
            class="pt-0"
            :bankData="bankData"
            :showHeaderPage="true"
            :isFreeMovement="false"
            :isDetail="true"
          />
        </template>

        <dependentDetails
          :propsDependents="dependents"
          :isActiveInsured="isActiveInsured"
          :insuredType="insuredType"
          :viewCheckbox="true"
        />
      </v-col>
    </v-row>

    <v-footer class="my-10">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            x-large
            block
            text
            color="textPrimary"
            @click="onClickBack()"
          >
            Voltar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            x-large
            block
            outlined
            color="textPrimary"
            @click="onClickCancel()"
          >
            Cancelar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import PrincipalInformations from '@/components/Beneficiary/Holder/PrincipalInformations.vue';
import PlanInformation from '@/components/Beneficiary/Contracted/PlanInformation.vue';
import ComplementaryInformations from '@/components/Beneficiary/Holder/ComplementaryInformations.vue';
import AddressInformations from '@/components/Beneficiary/Holder/AddressInformations.vue';
import BankInformations from '@/components/Beneficiary/Holder/BankInformations.vue';
import BeneficiaryContractedPlanService from '@/services-http/contract/BeneficiaryContractedPlanService';
import DependentContractedPlanService from '@/services-http/contract/DependentContractedPlanService';
import GenderService from '@/services-http/contract/GenderService';
import MaritalStatusService from '@/services-http/contract/MaritalStatusService';
import dependentDetails from '@/components/Beneficiary/Dependent/DependentDetails.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';

export default {
  name: 'Details',

  components: {
    PrincipalInformations,
    PlanInformation,
    ComplementaryInformations,
    AddressInformations,
    BankInformations,
    dependentDetails,
  },

  data: () => ({
    holderInformationData: {},
    contractedPlanData: {},
    holderComplementaryData: {},
    addressData: {},
    bankData: {},
    dependents: [],
    maritalStatus: [],
    genders: [],
    insuredType: '',
    overlay: false,
    showCardNumber: true,
    showRegistrationPlate: true,
    isActiveInsured: true,
    isRHProtegido: false,

    snackbar: {
      show: false,
      type: '',
      text: '',
    },
  }),

  mounted() {
    this.$refs.top.scrollIntoView({ behavior: 'smooth' });
    this.insuredType = this.$route.params.insuredType;
    this.getBeneficiaryByType();
    this.loadGenders();
    this.loadMaritalStatus();
  },

  mixins: [
    VerifyRoutesMixin,
  ],

  methods: {
    getBeneficiaryByType() {
      this.overlay = true;
      const contractedPlanId = JSON.parse(Number(sessionStorage.getItem('contractedPlanId')));
      if (this.insuredType === 'dependent') {
        const dependentContractedPlanService = new DependentContractedPlanService();
        this.showCardNumber = false;
        this.showRegistrationPlate = false;
        dependentContractedPlanService.FindInsuredByContractedPlanId(contractedPlanId).then((response) => {
          this.isActiveInsured = response.data.isActive;
          this.mapDataInsuredTypeDependent(response.data);
          this.overlay = false;
        }).catch(() => {
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao buscar os dados do beneficiário');
          this.overlay = false;
        });
      }
      if (this.insuredType === 'holder') {
        const beneficiaryContractedPlanService = new BeneficiaryContractedPlanService();
        beneficiaryContractedPlanService.FindInsuredByContractedPlanId(contractedPlanId).then((response) => {
          if (response && response.data) {
            this.isActiveInsured = response.data.isActive;
            this.mapDataInsuredTypeHolder(response.data);
          }
          this.overlay = false;
        }).catch(() => {
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao buscar os dados do beneficiário');
          this.overlay = false;
        });
      }
    },
    mapDataInsuredTypeDependent(response) {
      if (response) {
        this.holderInformationData = {
          document: response.parentPhysicalPersonCpf ? response.parentPhysicalPersonCpf : null,
          name: response.parentName ? response.parentName : null,
          admissionDate: response.admissionDate ? response.admissionDate : null,
        };

        this.contractedPlanData = {
          financialGroupId: response.financialGroupId ? response.financialGroupId : null,
          contractId: response.contractId ? Number(response.contractId) : null,
          subContractId: response.subcontractId ? Number(response.subcontractId) : null,
          eligibilityId: response.eligibilityId ? response.eligibilityId : null,
          planId: response.planId ? Number(response.planId) : null,
          startValidityDate: response.startDate ? response.startDate : null,
          endValidityDate: response.endDate ? response.endDate : null,
          contractedPlan: response.contractedPlan ? response.contractedPlan : null,
          cancellationReason: response.cancellationReason ? response.cancellationReason : null,
        };
        this.dependents.push(response);
      }
    },
    mapDataInsuredTypeHolder(response) {
      if (response) {
        this.setHolderInformationData(response);
        this.setContractedPlanData(response);
        this.setHolderComplementaryData(response);
        this.setAddressData(response);
        this.setBankData(response);

        if (response.dependents && response.dependents.length > 0) {
          response.dependents.forEach((element) => {
            if (element) {
              this.dependents.push(element);
            }
          });
        }
      }
    },
    setHolderInformationData(data) {
      this.holderInformationData = {
        document: data.physicalPersonCPF ? data.physicalPersonCPF : null,
        name: data.physicalPersonName ? data.physicalPersonName : null,
        registrationPlate: data.registrationPlate ? data.registrationPlate : null,
        registrationComplement: data.registrationComplement ? data.registrationComplement : null,
        cardNumber: data.memberCard ? data.memberCard : null,
        admissionDate: data.admissionDate ? data.admissionDate : null,
      };
    },
    setContractedPlanData(data) {
      this.contractedPlanData = {
        financialGroupId: data.financialGroupId ? data.financialGroupId : null,
        contractId: data.contractId ? Number(data.contractId) : null,
        subContractId: data.subcontractId ? Number(data.subcontractId) : null,
        eligibilityId: data.eligibilityId ? data.eligibilityId : null,
        planId: data.planId ? Number(data.planId) : null,
        startValidityDate: data.startDate ? data.startDate : null,
        endValidityDate: data.endDate ? data.endDate : null,
        contractedPlan: data.contractedPlan ? data.contractedPlan : null,
        familyCode: data.memberFamilyCode ? data.memberFamilyCode : null,
        familyCodeSequential: data.memberFamilyCodeSeq ? data.memberFamilyCodeSeq : null,
        cancellationReason: data.cancellationReason ? data.cancellationReason : null,
      };
    },
    setHolderComplementaryData(data) {
      this.holderComplementaryData = {
        birthDate: data.physicalPersonBirthDate ? data.physicalPersonBirthDate : null,
        maritalStatus: data.maritalStatusId ? data.maritalStatusId : null,
        gender: data.genderId ? data.genderId : null,
        motherName: data.mothersName ? data.mothersName : null,
        cns: data.cns ? data.cns : null,
        dnv: data.dnv ? this.formatter.unmaskDnv(data.dnv) : null,
        companyName: data.companyName ? data.companyName : null,
        companyDocumentNumber: data.companyDocumentNumber ? data.companyDocumentNumber : null,
        department: data.costCenterDepartment ? data.costCenterDepartment : null,
        capacityCode: data.taxAllocationCode ? data.taxAllocationCode : null,
        capacityDescription: data.taxAllocationDescription ? data.taxAllocationDescription : null,
        employmentRelationship: String(data.employmentRelationship ? data.employmentRelationship : null),
        workCard: data.professionalCardNumber ? data.professionalCardNumber : null,
        pisPasep: data.pisPasep ? data.pisPasep : null,
        job: data.insuredPosition ? data.insuredPosition : null,
        salary: data.salary ? data.salary : null,
        nationality: data.nationality ? data.nationality : null,
        stateBirth: data.stateBirth ? data.stateBirth : null,
        weight: data.weight ? data.weight : null,
        height: data.height ? data.height : null,
        rne: data.rne ? data.rne : null,
        telephone: data.homephoneDdd && data.homephoneNumber ? data.homephoneDdd + data.homephoneNumber : null,
        cellphone: data.cellphoneDdd && data.cellphoneNumber ? data.cellphoneDdd + data.cellphoneNumber : null,
        email: data.email ? data.email : null,
        identityDocumentNature: data.natureIdentifyDocument ? data.natureIdentifyDocument : null,
        identityDocumentNumber: data.identityDocumentNumber ? data.identityDocumentNumber : null,
        identityDocumentEmissionDate: data.identityDocumentEmissionDate ? data.identityDocumentEmissionDate : null,
        identityDocumentIssuer: data.identityDocumentIssuer ? data.identityDocumentIssuer : null,
        identityDocumentState: data.documentStateIssuer ? data.documentStateIssuer : null,
        identityDocumentCountry: data.documentCountryIssue ? data.documentCountryIssue : null,
      };
    },
    setAddressData(data) {
      if (data.activeAddress !== null) {
        this.addressData = {
          city: data.activeAddress.addressCity ? data.activeAddress.addressCity : null,
          log: data.activeAddress.address ? data.activeAddress.address : null,
          code: data.activeAddress.addressZip ? data.activeAddress.addressZip : null,
          country: data.activeAddress.addressCountry ? data.activeAddress.addressCountry : null,
          neighborhood: data.activeAddress.addressNeighborhood ? data.activeAddress.addressNeighborhood : null,
          number: data.activeAddress.addressNumber ? data.activeAddress.addressNumber : null,
          region: data.activeAddress.addressState ? data.activeAddress.addressState : null,
          complement: data.activeAddress.addressComplement ? data.activeAddress.addressComplement : null,
          logType: data.activeAddress.addressType ? data.activeAddress.addressType.toUpperCase() : null,
        };
      }
    },
    setBankData(data) {
      if (data.activeBankAccount !== null) {
        this.bankData = {
          code: data.activeBankAccount.financialBank && data.activeBankAccount.financialBank.code ? Number(data.activeBankAccount.financialBank.code) : null,
          agency: data.activeBankAccount.agency ? data.activeBankAccount.agency : null,
          agencyDigit: data.activeBankAccount.agencyDigit ? data.activeBankAccount.agencyDigit : null,
          account: data.activeBankAccount.accountNumber ? data.activeBankAccount.accountNumber : null,
          accountDigit: data.activeBankAccount.accountDigit ? data.activeBankAccount.accountDigit : null,
        };
      }
    },
    async loadGenders() {
      await this.genderService.FindAll().then((response) => {
        if (response && response.data) {
          this.genders = response.data;
        }
      });
    },
    async loadMaritalStatus() {
      await this.maritalStatusService.FindAll().then((response) => {
        if (response && response.data) {
          this.maritalStatus = response.data;
        }
      });
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    onClickBack() {
      this.$router.back();
    },
    onClickCancel() {
      const routeName = this.isRHProtegido ? 'HomeRHProtegido' : 'HomeAllMenus';
      this.redirectRouter(routeName, { isRHProtegido: this.isRHProtegido });
    },
  },

  created() {
    this.genderService = new GenderService();
    this.maritalStatusService = new MaritalStatusService();
  },
};
</script>
